import { get, set } from 'lodash';
import { transformPerk } from 'common/helpers/perks';
import Repository from './Repository';

const repository = new Repository({ baseUrl: '/giving' });

repository.find = async function (queryJson) {
    const queryString = Repository.prepareQueryString(queryJson);
    const result = await this.axios.get(queryString ? `?${queryString}` : '');
    const docs = get(result, 'data.docs', []);
    if (docs.length) {
        set(result, 'data.docs', docs.map(transformPerk));
    }
    return result;
};

repository.moveUp = async function (id) {
    return await this.axios.post(`/${id}/move-up`).then(res => res.data);
};

repository.moveDown = async function (id) {
    return await this.axios.post(`/${id}/move-down`).then(res => res.data);
};

repository.hiddenRecurring = async function (data) {
    return await this.axios
        .post(`/hidden-recurring`, data)
        .then(res => res.data);
};

repository.hiddenByCurrency = async function (data) {
    return await this.axios
        .post(`/hidden-by-currency`, data)
        .then(res => res.data);
};

export default repository;
