import fromPairs from 'lodash/fromPairs';
import { paymentMethodConfigProp } from 'common/helpers/paymentMethods';
import {
    CAMPAIGN_CONFETTI_TRIGGER_TYPES,
    CAMPAIGN_PAGE_DESIGNS,
    GATEWAYS,
    PAYMENT_GATEWAYS,
} from 'common/constants';
import { gatewaySupportsPaymentMethodAndCurrency } from 'common/helpers/gateways';

export {
    DEFAULT_CHECKOUT_CUSTOM_FIELDS,
    GATEWAY_SPECIFIC_CHECKOUT_FIELDS,
} from 'common/constants/checkout';

export const CAMPAIGN_FORM_TABS = [
    'start',
    'settings',
    'creative',
    'givingOptions',
    'checkout',
];

export const seo = {
    robots: {
        follow: 'follow',
        index: 'index',
    },
    shareButtons: true,
};

export const locales = {
    language: 'en',
    pageName: 'Page1',
    goalCurrency: 'USD',
    goal: 10_000,
    banner: {
        type: 'image',
        isVideoEnabled: false,
        isMobileImagesEnabled: false,
        images: [null],
        video: {
            width: '640',
            height: '360',
        },
    },
    isMobileBodyImageEnabled: false,
    displayPageName: false,
    enableMatchMyGift: true,
    matchMyGiftEmailEnabled: true,
    isDonorPflEnabled: true,
    isDonorPflEmailEnabled: false,
};

export const getGatewaySpecificPaymentDefaults = (
    gateway = PAYMENT_GATEWAYS.STRIPE,
    currency = 'USD',
) => {
    const config = {
        gateway,
        installmentMaxMonths: '12',
        installmentDefaultMonths: '12',
        recurringMaxMonths: '12',
        recurringDefaultMonths: '12',
        allowSinglePayment: GATEWAYS[gateway].regular,
        allowInstallments: GATEWAYS[gateway].installments,
        allowRecurring: GATEWAYS[gateway].monthly,
    };
    const suppportedPaymentMethods = Object.keys(
        GATEWAYS[gateway]?.support,
    ).filter(method =>
        gatewaySupportsPaymentMethodAndCurrency(gateway, currency, method),
    );
    const paymentMethodsConfig = fromPairs(
        suppportedPaymentMethods.map(method => [
            paymentMethodConfigProp(method),
            method !== 'ideal',
        ]),
    );

    return {
        ...config,
        ...paymentMethodsConfig,
    };
};

export const paymentCurrencies = ['USD'];

export const payments = {
    currencies: ['USD'],
    USD: [
        {
            ...getGatewaySpecificPaymentDefaults(
                PAYMENT_GATEWAYS.STRIPE,
                'USD',
            ),
        },
    ],
};

export default {
    pages: ['Page1'],
    defaultPage: 'Page1',
    recurringType: 0,
    type: 1,
    matchRatio: 1,
    goalType: 1,
    fundingType: 1,
    priority: 0,
    baseCurrency: 'USD',
    abandonedSettings: {},
    slugs: {},
    landing: {
        enableTimer: true,
        enableConfetti: false,
        useUpdatedPerkDesign: true,
        useOrgLogoAsPerkLogo: true,
        pageDesign: CAMPAIGN_PAGE_DESIGNS.CLASSIC,
        confettiTriggerType: CAMPAIGN_CONFETTI_TRIGGER_TYPES.GOAL_REACHED,
        locales: {
            Page1: {
                ...seo,
            },
        },
    },
    payments: {
        Page1: {
            ...payments,
        },
    },
    bonus: {
        rounds: [
            {
                enabled: false,
                activationType: 1,
                matchRatio: 1,
                goal: {},
            },
        ],
        maxRounds: 2,
        active: false,
        activeRound: '',
    },
    locales: {
        Page1: {
            ...locales,
        },
    },
    checkout: {
        locales: {
            Page1: {
                sameAsCampaign: true,
            },
        },
    },
    checkoutFields: {},
    currencyGoals: {
        primaryCurrency: null,
        currencies: {},
    },
    suggestedAmountsSettings: {},
    donationSettings: {},
    customLogicSettings: {},
};
