import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from 'client/componentLibrary/Card';
import { createPageOptionsSelector } from 'client/components/GivingOptionsEditor/givingSelectors';
import ExpandArrows from 'client/components/Icons/ExpandArrows';
import filterQueryParams from 'client/helpers/filterQueryParams';
import { currencySign } from 'common/helpers';
import { isValidColor } from 'common/helpers/color';
import { changeColorAlpha } from '../../../../../../helpers/colorUtils';
import {
    selectPageName,
    selectSelectedCampaign,
    selectSelectedLayerItem,
} from '../../../../features/campaign/campaignSlice';
import { open } from '../../../../features/checkout/checkoutPopupSlice';
import {
    updateDonationAmount,
    updateDonationCurrency,
    updateGivingAmount,
    updateGivingCount,
    updateGivingId,
    updateGivingPaymentOptions,
    updatePerkPrices,
} from '../../../../features/checkout/checkoutSlice';
import { slice as teamSlice } from '../../../../features/layersList/teamsListSlice';
import { selectTabs } from '../../../../features/recentDonationPanel/recentDonationPanelSlice';
import PerkPopup from './PerkPopup';
import { PerkContent } from './PerkContent';
import PerkGifts from './PerkGifts';
import PerkActions from './PerkActions';
import { PAYMENT_MODES } from 'common/constants';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        flex: `0 0 calc(25% - ${spacing(2)}px)`,
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
        '&.perk-3': {
            flex: `0 0 calc(33.33% - ${spacing(2)}px)`,
        },
        '&.perk-4': {
            flex: `0 0 calc(25% - ${spacing(2)}px)`,
        },
        '&.perk-5': {
            flex: `0 0 calc(20% - ${spacing(2)}px)`,
        },
        '@media (max-width: 480px)': {
            maxWidth: '100%',
            justifySelf: 'center',
            flexBasis: '100%',
        },
        '@media (max-width: 1024px)': {
            maxWidth: '100%',
            flex: `0 0 calc(50% - ${spacing(2)}px) !important`,
        },
        backgroundColor: palette.background.paper,
        overflow: 'hidden',
    },
    topBar: {
        position: 'absolute',
        top: spacing(0.75),
        right: spacing(0.75),
        'html[dir=rtl] &': {
            right: 'auto',
            left: spacing(0.75),
        },
    },
    imagePlaceholder: {
        margin: 0,
        marginInlineEnd: spacing(1),
        '& img': {
            width: 100,
            height: 100,
            borderRadius: spacing(2),
        },
        '.perk-4 & img': {
            width: 100,
            height: 100,
        },

        '.perk-5 & img': {
            width: 65,
            height: 65,
        },
        '@media (max-width: 480px)': {
            '& img': {
                width: 80,
                height: 80,
            },
        },
    },
    largeLogo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        '& img': {
            width: '120px !important',
            height: '120px !important',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(1),
        flex: 1,
        alignItems: 'center',
        cursor: 'pointer',
        wordBreak: 'break-word',
        fontFamily: typography.fontFamily,
    },
    centerContent: {
        textAlign: 'center',
    },
    contentSection: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
    },
    columnContentSection: {
        flexDirection: 'column',
        gap: spacing(0.5),
    },
    text: {
        display: 'flex',
        width: '100%',
        lineHeight: '1.4',
    },
    contentTop: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        gap: spacing(0.5),
    },
    contentEndGutter: {
        marginInlineEnd: spacing(2),
    },
    popupContent: {
        marginInlineEnd: spacing(3),
        overflowY: 'auto',
    },
    amountLabel: {
        fontSize: typography.pxToRem(18),
    },
    amount: {
        fontWeight: 600,
        fontSize: typography.pxToRem(18),
        display: 'flex',
        flexWrap: 'wrap',
    },
    largeAmount: {
        justifyContent: 'center',
        fontSize: typography.pxToRem(20),
        '.perk-5 &': {
            fontSize: typography.pxToRem(16),
        },
    },
    priceLabel: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: typography.pxToRem(22),
        fontWeight: 600,
    },
    title: {
        color: palette.text.primary,
        fontSize: typography.pxToRem(18),
        fontWeight: 'bold',
        '.perk-5 &': {
            fontSize: typography.pxToRem(16),
        },
    },
    largeTitle: {
        textAlign: 'center',
        fontSize: typography.pxToRem(20),
        '.perk-5 &': {
            fontSize: typography.pxToRem(17),
        },
    },
    description: {
        color: palette.text.primary,
        fontSize: typography.pxToRem(14),
        fontWeight: 400,
        margin: '0',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    largeDescription: {
        fontSize: '1rem',
    },
    lineClamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    gifts: {
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
        padding: spacing(1),
    },
    giftsData: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        fontSize: 18,
        fontFamily: typography.fontFamily,
    },
    stockCount: {
        color: palette.text.primary,
    },
    bold: {
        fontWeight: 600,
    },
    counterBtn: {
        cursor: 'pointer',
        padding: spacing(1),
        display: 'flex',
        alignItems: 'center',
        backgroundColor: palette.background.paperBorder,
        '& svg': {
            stroke: palette.text.primary,
            fontSize: typography.pxToRem(14),
        },
        '&.disabled': {
            '& svg': {
                stroke: changeColorAlpha(palette.text.primary, 0.2),
            },
            cursor: 'no-drop',
            pointerEvents: 'auto',
        },
        '@media (max-width: 480px)': {
            padding: spacing(2),
            justifyContent: 'center',
            '& svg': {
                padding: spacing(0.5),
            },
        },
    },
    roundCounterBtnStart: {
        '@media (max-width: 480px)': {
            borderRadius: `${spacing(1)}px 0 0 ${spacing(1)}px`,
            'html[dir=rtl] &': {
                borderRadius: `0 ${spacing(1)}px ${spacing(1)}px 0`,
            },
        },
    },
    roundCounterBtnEnd: {
        '@media (max-width: 480px)': {
            borderRadius: `0 ${spacing(1)}px ${spacing(1)}px 0`,
            'html[dir=rtl] &': {
                borderRadius: `${spacing(1)}px 0 0 ${spacing(1)}px`,
            },
        },
    },
    disabled: {
        opacity: 0.35,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        zIndex: 0,
        '@media (max-width: 480px)': {
            padding: spacing(1.5),
            justifyContent: 'end',
        },
    },
    centerActions: {
        '@media (max-width: 480px)': {
            justifyContent: 'center',
        },
    },
    actionRow: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        '@media (max-width: 480px)': {
            width: 'auto',
        },
    },
    counterBuffer: {
        marginTop: spacing(1),
    },
    donateButton: {
        fontFamily: typography.fontFamily,
        position: 'relative',
        flex: 1,
        '@media (max-width: 480px)': {
            flex: '0 1 auto',
            fontSize: typography.pxToRem(14),
            height: '100%',
        },
    },
    outOfStockLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: palette.accent.dark,
        color: palette.accent.contrastText,
        padding: `${spacing(0.5)}px ${spacing(1)}px`,
        zIndex: 1,
        borderRadius: spacing(1),
        pointerEvents: 'none',
    },
    perkCount: {
        alignItems: 'center',
        background: palette.primary.main,
        borderRadius: '50%',
        color: palette.primary.contrastText,
        display: 'flex',
        fontSize: '1rem',
        fontWeight: 600,
        height: '1.1rem',
        justifyContent: 'center',
        padding: spacing(0.5),
        position: 'absolute',
        right: 0,
        top: '-1rem',
        width: '1.1rem',
        zIndex: 1,
        fontFamily: typography.fontFamily,
        'html[dir=rtl] &': {
            right: 'auto',
            left: 0,
        },
    },
    total: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing(1),
        paddingBottom: spacing(2),
        fontFamily: typography.fontFamily,
    },
    totalAmount: {
        fontWeight: 600,
        fontSize: typography.pxToRem(18),
    },
}));

const Perk = ({
    id,
    logo,
    color,
    inStock,
    className,
    isShowAmountSold,
    isShowAmountLeft,
    isBecomeGrey,
    isLimited,
    isSelectEnabled,
    limit,
    isStock,
    soldCount,
    paymentOptions,
    duration,
    disabled,
    isNameEnabled,
    isDescriptionEnabled,
    isPriceEnabled,
    enableRecurringPayments,
    enableRegularPayments,
    ...restProps
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { palette } = useTheme();
    const classes = useStyles();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [shouldShowOpenBtn, setShouldShowOpenBtn] = useState(false);
    const [count, setCount] = useState(1);

    const pageName = useSelector(selectPageName);
    const campaign = useSelector(selectSelectedCampaign);
    const tab = useSelector(selectTabs);
    const selectPageOptions = useCallback(
        createPageOptionsSelector(pageName, campaign.defaultPage),
        [pageName, campaign?.defaultPage],
    );
    const selectedLayerItemOnCampaignPage = useSelector(
        teamSlice.selectors.selectSelectedItem,
    );
    const selectedLayerItemOnLayerPage = useSelector(selectSelectedLayerItem);

    const amount = useMemo(
        () => selectPageOptions(restProps, 'amount'),
        [selectPageOptions],
    );
    const recurringAmount = useMemo(
        () => selectPageOptions(restProps, 'recurringAmount'),
        [selectPageOptions],
    );

    const name = useMemo(
        () => selectPageOptions(restProps, 'name'),
        [selectPageOptions],
    );
    const description = useMemo(
        () => selectPageOptions(restProps, 'description'),
        [selectPageOptions],
    );

    const currency = useMemo(
        () => selectPageOptions(restProps, 'currency'),
        [selectPageOptions],
    );
    const isActive = useMemo(
        () => selectPageOptions(restProps, 'isActive'),
        [selectPageOptions],
    );

    const perkPrices = {
        regular: { amount },
        recurring: {
            amount: recurringAmount,
        },
    };
    const defaultPaymentMode =
        paymentOptions === 'recurring'
            ? PAYMENT_MODES.RECURRING
            : PAYMENT_MODES.REGULAR;
    const { amount: perkAmount } = perkPrices[defaultPaymentMode];
    const totalAmount = perkAmount * count;

    const hasName = Boolean(name?.length) && isNameEnabled;
    const hasDescription = Boolean(description?.length) && isDescriptionEnabled;
    const hasAmount = perkAmount > 0;
    const noLogo = !logo;

    const currentlyInStock = inStock - soldCount;
    const isStockEmpty = isStock && currentlyInStock <= 0;
    const shouldShowGiftsSection =
        !!inStock && (isShowAmountLeft || isShowAmountSold);
    const shouldShowCounter =
        isSelectEnabled && !(isLimited && limit <= 1) && hasAmount;

    let selectedLayerItem =
        selectedLayerItemOnLayerPage || selectedLayerItemOnCampaignPage;

    if (selectedLayerItem) {
        const layerId = selectedLayerItem.layerId;
        tab.map(layer => {
            if (layer.layer) {
                if (layer.layer.id === layerId && !layer.layer.allowDonations) {
                    selectedLayerItem = null;
                }
            }
        });
    }

    const getCanDecreaseCounter = () => isSelectEnabled && count > 1;
    const getCanIncreaseCounter = () => {
        if (disabled) {
            return false;
        }
        if (isSelectEnabled) {
            if (isStock) {
                return (
                    count < currentlyInStock &&
                    (isLimited ? count < limit : true)
                );
            }
            if (isLimited) {
                return count < limit;
            }
        }
        return isSelectEnabled;
    };

    const increaseCounter = () => {
        if (getCanIncreaseCounter()) {
            setCount(count + 1);
        }
    };

    const decreaseCounter = () => {
        if (getCanDecreaseCounter()) {
            setCount(count - 1);
        }
    };

    const renderPerkAmountLabel = amountType => {
        const recurringCount = paymentOptions === 'recurring' ? duration : 1;

        return (
            <div dir="ltr">
                <span>
                    {currencySign(currency)}
                    {amountType === 'total'
                        ? totalAmount.toLocaleString('en-EN')
                        : perkAmount.toLocaleString('en-EN')}
                </span>
                {recurringCount > 1 && <span>×{recurringCount}</span>}
            </div>
        );
    };

    const handleDonate = useCallback(() => {
        if (isStockEmpty) {
            return null;
        }
        dispatch(updateDonationAmount(totalAmount));
        dispatch(updateGivingCount(count));
        dispatch(updateGivingAmount(perkAmount));
        dispatch(
            updateGivingPaymentOptions({
                type: paymentOptions,
                duration: duration,
                enableRegularPayments,
                enableRecurringPayments,
            }),
        );
        dispatch(updatePerkPrices(perkPrices));
        dispatch(updateDonationCurrency(currency));
        dispatch(open({ perk: id, layerItem: selectedLayerItem }));
        dispatch(updateGivingId(id));
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        history.push(
            `${history.location.pathname.replace(
                /\/+$/,
                '',
            )}/donate/${queryString}`,
        );
    }, [count, selectedLayerItem]);

    const getImageBorderColor = () => {
        if (isBecomeGrey && isStockEmpty) {
            return palette.grey[200];
        }
        if (isValidColor(color)) {
            return color;
        }
        return palette.primary.main;
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };
    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const renderStockLabel = () => {
        if (!isShowAmountLeft) {
            return isShowAmountSold ? (
                <>
                    <span className={classes.stockCount}>{soldCount}</span>{' '}
                    <span>
                        <FormattedMessage id="Perks.giftsSold" />
                    </span>
                </>
            ) : (
                <></>
            );
        }

        return (
            <>
                <span className={classes.stockCount}>
                    <span className={classes.bold}>{currentlyInStock}</span>
                    {isShowAmountSold && inStock >= 0 && (
                        <span>/{inStock}</span>
                    )}
                </span>{' '}
                <span className={classes.stockText}>
                    <FormattedMessage id="Perks.giftsLeft" />
                </span>
            </>
        );
    };

    if (!isActive) {
        return null;
    }

    return (
        <Card className={classNames(classes.root, className)}>
            {isStockEmpty && (
                <div className={classes.outOfStockLabel}>
                    <FormattedMessage id="Perks.soldOut" />
                </div>
            )}
            <div className={classNames(classes.topBar)}>
                {shouldShowOpenBtn && (
                    <div
                        className="perk-popup-open-btn"
                        onClick={handleOpenPopup}
                    >
                        <ExpandArrows />
                    </div>
                )}
                <PerkPopup
                    isPopupOpen={isPopupOpen}
                    handleClosePopup={handleClosePopup}
                    classes={classes}
                >
                    <PerkContent
                        addEndGutter={false}
                        classes={classes}
                        className={classes.popupContent}
                        description={description}
                        handleOpenPopup={handleOpenPopup}
                        hasAmount={hasAmount}
                        hasDescription={hasDescription}
                        hasName={hasName}
                        imageBorderColor={getImageBorderColor()}
                        logo={logo}
                        name={name}
                        noLogo={noLogo}
                        renderPerkAmountLabel={renderPerkAmountLabel}
                        showAmountLabel={false}
                        showPerkPrice={isPriceEnabled}
                    />
                    <PerkGifts
                        classes={classes}
                        disabled={disabled}
                        isStockEmpty={isStockEmpty}
                        shouldShowGiftsSection={shouldShowGiftsSection}
                        stockLabel={renderStockLabel()}
                    />

                    {hasAmount && (
                        <div
                            className={classNames(classes.total, {
                                [classes.disabled]: disabled || isStockEmpty,
                            })}
                        >
                            <span>
                                <FormattedMessage id="DonationForm.totalAmount" />
                            </span>
                            <span className={classes.totalAmount}>
                                {renderPerkAmountLabel('total')}
                            </span>
                        </div>
                    )}
                    <PerkActions
                        canDecreaseCounter={getCanDecreaseCounter()}
                        canIncreaseCounter={getCanIncreaseCounter()}
                        classes={classes}
                        count={count}
                        decreaseCounter={decreaseCounter}
                        disabled={disabled}
                        handleDonateClick={handleDonate}
                        increaseCounter={increaseCounter}
                        isSelectEnabled={isSelectEnabled}
                        shouldShowCounter={shouldShowCounter}
                    />
                </PerkPopup>
            </div>
            <PerkContent
                addEndGutter={shouldShowOpenBtn}
                clamp
                classes={classes}
                description={description}
                disabled={disabled || isStockEmpty}
                handleOpenPopup={handleOpenPopup}
                hasAmount={hasAmount}
                hasDescription={hasDescription}
                hasName={hasName}
                imageBorderColor={getImageBorderColor()}
                logo={logo}
                name={name}
                noLogo={noLogo}
                onLinesClamped={() => {
                    setShouldShowOpenBtn(true);
                }}
                renderPerkAmountLabel={renderPerkAmountLabel}
            />

            <PerkGifts
                classes={classes}
                disabled={disabled}
                isStockEmpty={isStockEmpty}
                shouldShowGiftsSection={shouldShowGiftsSection}
                stockLabel={renderStockLabel()}
            />

            <PerkActions
                canDecreaseCounter={getCanDecreaseCounter()}
                canIncreaseCounter={getCanIncreaseCounter()}
                classes={classes}
                count={count}
                decreaseCounter={decreaseCounter}
                disabled={disabled}
                handleDonateClick={handleDonate}
                increaseCounter={increaseCounter}
                isSelectEnabled={isSelectEnabled}
                shouldShowCounter={shouldShowCounter}
            />
        </Card>
    );
};

Perk.propTypes = {
    id: PropTypes.number,
    logo: PropTypes.string,
    color: PropTypes.string,
    inStock: PropTypes.number,
    className: PropTypes.string,
    isShowAmountSold: PropTypes.bool,
    isShowAmountLeft: PropTypes.bool,
    isBecomeGrey: PropTypes.bool,
    isLimited: PropTypes.bool,
    isSelectEnabled: PropTypes.bool,
    limit: PropTypes.number,
    isStock: PropTypes.bool,
    soldCount: PropTypes.number,
    paymentOptions: PropTypes.string,
    duration: PropTypes.number,
    disabled: PropTypes.bool,
    isNameEnabled: PropTypes.bool,
    isDescriptionEnabled: PropTypes.bool,
    isPriceEnabled: PropTypes.bool,
    enableRecurringPayments: PropTypes.bool.isRequired,
    enableRegularPayments: PropTypes.bool.isRequired,
};

export default Perk;
