const { mapValues } = require('lodash');
const { PERKS_PAYMENT_OPTIONS } = require('../constants/perks');

function transformPerk(givingData) {
    if (
        !givingData.enableRegularPayments &&
        !givingData.enableRecurringPayments
    ) {
        const pageOptions = mapValues(givingData.pageOptions, pageOptions => {
            if (givingData.paymentOptions === PERKS_PAYMENT_OPTIONS.RECURRING) {
                return {
                    ...pageOptions,
                    amount: null,
                    recurringAmount: pageOptions.amount,
                };
            }
            return pageOptions;
        });
        return {
            ...givingData,
            pageOptions,
            enableRecurringPayments:
                givingData.paymentOptions === PERKS_PAYMENT_OPTIONS.RECURRING,
            enableRegularPayments:
                givingData.paymentOptions === PERKS_PAYMENT_OPTIONS.SINGLE,
        };
    }
    return { ...givingData };
}

module.exports = {
    transformPerk,
};
