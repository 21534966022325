import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Ticket } from './ticket.svg';
import { Button, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import StarDivider from '../../../StarDivider/StarDivider';
import { open } from '../../../../features/checkout/checkoutPopupSlice';
import {
    updateDonationAmount,
    updateDonationCurrency,
    updateGivingCount,
    updateGivingId,
    updateGivingAmount,
    updateGivingPaymentOptions,
    updatePerkPrices,
} from '../../../../features/checkout/checkoutSlice';
import { currencySign } from 'common/helpers';
import { FormattedMessage } from 'react-intl';
import {
    selectPageName,
    selectSelectedCampaign,
    selectSelectedLayerItem,
} from '../../../../features/campaign/campaignSlice';
import { selectTabs } from '../../../../features/recentDonationPanel/recentDonationPanelSlice';
import { slice as teamSlice } from '../../../../features/layersList/teamsListSlice';
import { createPageOptionsSelector } from 'client/components/GivingOptionsEditor/givingSelectors';
import filterQueryParams from 'client/helpers/filterQueryParams';
import { changeColorAlpha } from '../../../../../../helpers/colorUtils';
import { PAYMENT_MODES } from 'common/constants';
import { PERKS_PAYMENT_OPTIONS } from 'common/constants/perks';

const useStyles = makeStyles(({ palette, spacing }) => ({
    perkItem: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        '&.perk-3': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '&.perk-4': {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '&.perk-5': {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        },
    },
    imagePlaceholder: {
        margin: 0,
        minHeight: 125,
        width: '100%',
        '& img': {
            width: 129,
            height: 129,
            borderRadius: '50%',
            '@media (max-width: 480px)': {
                width: '100px !important',
                height: '100px !important',
            },
        },
        '.perk-3 &': {
            marginBottom: 15,
        },
        '.perk-4 &': {
            marginBottom: 15,
        },
        '.perk-4 & img': {
            width: 115,
            height: 115,
        },
        '.perk-5 &': {
            marginBottom: 15,
        },
        '.perk-5 & img': {
            width: 115,
            height: 115,
        },
    },
    noImage: {
        minHeight: 0,
        '@media (max-width: 480px)': {
            height: 0,
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        '& .divider': {
            margin: '5px 0',
        },
    },
    text: {
        borderTop: `2px solid ${palette.grey[400]}`,
        padding: '5px 0',
        minHeight: 48,
        '&.no-border': {
            border: 'none',
        },
        '@media(max-width: 480px)': {
            minHeight: 52,
        },
        '.perk-4 & ': {
            display: 'flex',
            flexDirection: 'column',
        },
        '.perk-5 & ': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    perkAmount: {
        color: palette.grey[700],
        fontSize: '1.688rem',
        fontWeight: 700,
        display: 'inline-block',
        '@media (max-width: 480px)': {
            fontSize: '1rem',
        },
        '.perk-4 &': {
            fontSize: '1.563rem',
            '@media (max-width: 480px)': {
                fontSize: '1rem',
            },
        },
        '.perk-5 &': {
            fontSize: '1.563rem',
            '@media (max-width: 480px)': {
                fontSize: '1rem',
            },
        },
    },
    title: {
        display: 'inline-block',
        color: palette.grey[700],
        fontSize: '1rem',
        fontWeight: 'bold',
        '@media (max-width: 480px)': {
            fontSize: '0.75rem',
        },
        '.perk-4 &': {
            '@media (max-width: 480px)': {
                fontSize: '0.75rem',
            },
        },
        '.perk-5 &': {
            '@media (max-width: 480px)': {
                fontSize: '0.75rem',
            },
        },
    },
    paragraph: {
        display: 'inline-block',
        color: palette.grey[700],
        fontSize: '0.875rem',
        fontWeight: 300,
        margin: 0,
        whiteSpace: 'pre-wrap',
        '@media (max-width: 480px)': {
            fontWeight: 500,
            fontSize: '0.688rem',
        },
        '.perk-4 &': {
            '@media (max-width: 480px)': {
                fontWeight: 500,
                fontSize: '0.688rem',
            },
        },
        '.perk-5 &': {
            '@media (max-width: 480px)': {
                fontWeight: 500,
                fontSize: '0.688rem',
            },
        },
    },
    ticketGroup: {
        '@media (max-width: 480px)': {
            display: 'flex',
            marginBottom: 2,
            '& svg': {
                display: 'block',
                marginRight: '2px',
            },
        },
    },
    leftAmount: {
        display: 'inline-block',
        fontSize: '0.938rem',
        color: palette.grey[700],
        marginLeft: spacing(0.5),
        fontWeight: 'bold',
        'html[dir=rtl] &': {
            marginLeft: 0,
            marginRight: spacing(0.5),
        },
        '@media (max-width: 480px)': {
            fontSize: '11px !important',
            color: `${palette.primary.main} !important`,
            marginLeft: 0,
            marginRight: 3,
            'html[dir=rtl] &': {
                marginLeft: 3,
                marginRight: 0,
            },
        },
    },
    soldAmount: {
        display: 'inline-block',
        fontSize: '0.938rem',
        color: palette.grey[700],
        marginLeft: 4,
        fontWeight: 'bold',
        'html[dir=rtl] &': {
            marginLeft: 0,
            marginRight: spacing(0.5),
        },
        '@media (max-width: 480px)': {
            fontSize: '0.688rem !important',
            color: `${palette.primary.main} !important`,
            marginLeft: 0,
            marginRight: 3,
            'html[dir=rtl] &': {
                marginLeft: 3,
                marginRight: 0,
            },
        },
    },
    gifts: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: '5px 0',
        borderBottom: `2px solid ${palette.grey[400]}`,
        marginBottom: '10px',
        borderTop: `2px solid ${palette.grey[400]}`,
        '& span': {
            display: 'inline-block',
            fontSize: '0.938rem',
            color: palette.grey[600],
            fontWeight: 'normal',
            '@media (max-width: 480px)': {
                fontSize: '0.688rem',
                fontWeight: 500,
            },
        },
        '.perk-4 & span': {
            '@media (max-width: 480px)': {
                fontSize: '0.688rem',
                fontWeight: 500,
            },
        },
        '.perk-5 & span': {
            '@media (max-width: 480px)': {
                fontSize: '0.688rem',
                fontWeight: 500,
            },
        },
    },
    selectUnits: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: 10,
        background: palette.grey[200],
        padding: 5,
        '@media (max-width: 480px)': {
            margin: '10px 0',
        },
        '& p': {
            margin: 0,
            fontSize: '0.875rem',
            fontWeight: 400,
            color: palette.primary.main,
            width: '100%',
            '@media (max-width: 480px)': {
                fontSize: '0.688rem',
                lineHeight: 1.2,
                fontWeight: 500,
            },
        },
    },
    unitControls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'strech',
        width: '100%',
        height: '100%',
        paddingLeft: spacing(1),
        '& button': {
            display: 'inline-block',
            width: '12px',
            padding: '0',
            position: 'relative',
            color: palette.primary.main,
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            '&:before': {
                content: "''",
                position: 'absolute',
                top: 'calc(50% - 2px)',
                left: 0,
                width: '100%',
                height: 2,
                marginTop: 2,
                background: palette.primary.main,
            },
            '@media (max-width: 480px)': {
                width: '10px',
                fontWeight: 'normal',
                fontSize: '1rem',
            },
        },
    },
    plus: {
        '&:after': {
            content: "''",
            position: 'absolute',
            top: 'calc(50% - 2px)',
            left: 0,
            width: '100%',
            height: 2,
            marginTop: 2,
            background: palette.primary.main,
            transform: 'rotate(90deg)',
        },
    },
    unitsCount: {
        display: 'inline-block',
        width: 25,
        height: 25,
        margin: '0 10px',
        fontSize: '0.875rem !important',
        lineHeight: '25px',
        textAlign: 'center',
        borderRadius: '50%',
        border: `1px solid ${palette.primary.main}`,
        color: palette.primary.main,
        cursor: 'default',
        background: palette.common.white,
        '@media (max-width: 480px)': {
            margin: '0 8px',
            fontSize: '0.688rem !important',
            height: 20,
            width: 20,
            lineHeight: '21px',
        },
    },
    giftsItem: {
        justifySelf: 'center',
        '@media (max-width: 480px)': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
        },
    },
    perkBottom: {
        marginTop: 'auto',
    },
}));

const PerkButton = withStyles(({ palette }) => ({
    root: {
        background: palette.secondary.main,
        borderRadius: 18,
        border: 0,
        color: 'white',
        height: 'auto',
        width: 208,
        alignSelf: 'center',
        boxShadow: `0px 0px 6px ${changeColorAlpha(
            palette.text.primary,
            0.15,
        )}`,
        transition: 'background, 0.25 ease',
        '@media (max-width: 480px)': {
            width: '100%',
        },
        '& span': {
            '@media (max-width: 480px)': {
                fontSize: '0.813rem',
            },
        },
        '&:hover': {
            background: palette.secondary.main,
        },
        '&[disabled]': {
            background: 'none',
            backgroundColor: palette.grey[200],
            pointerEvents: 'none',
        },
    },
    label: {
        textTransform: 'capitalize',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        lineHeight: 1,
        letterSpacing: 0,
        '.perk-4 &': {
            fontSize: '1rem',
        },
    },
}))(Button);

function Perk(props) {
    const classes = useStyles();
    const {
        id,
        logo,
        color,
        inStock,
        className,
        isShowAmountSold,
        isShowAmountLeft,
        isBecomeGrey,
        isLimited,
        isSelectEnabled,
        limit,
        isStock,
        soldCount,
        paymentOptions,
        duration,
        disabled,
        isNameEnabled,
        isDescriptionEnabled,
        isPriceEnabled,
        enableRecurringPayments,
        enableRegularPayments,
    } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const { palette } = useTheme();
    const pageName = useSelector(selectPageName);
    const campaign = useSelector(selectSelectedCampaign);
    const [count, setCount] = useState(1);
    const tab = useSelector(selectTabs);
    const selectPageOptions = useCallback(
        createPageOptionsSelector(pageName, campaign.defaultPage),
        [pageName, campaign?.defaultPage],
    );
    const selectedLayerItemOnCampaignPage = useSelector(
        teamSlice.selectors.selectSelectedItem,
    );
    const selectedLayerItemOnLayerPage = useSelector(selectSelectedLayerItem);
    const amount = useMemo(
        () => selectPageOptions(props, 'amount'),
        [selectPageOptions],
    );
    const recurringAmount = useMemo(
        () => selectPageOptions(props, 'recurringAmount'),
        [selectPageOptions],
    );
    const name = useMemo(
        () => selectPageOptions(props, 'name'),
        [selectPageOptions],
    );
    const description = useMemo(
        () => selectPageOptions(props, 'description'),
        [selectPageOptions],
    );
    const currency = useMemo(
        () => selectPageOptions(props, 'currency'),
        [selectPageOptions],
    );
    const isActive = useMemo(
        () => selectPageOptions(props, 'isActive'),
        [selectPageOptions],
    );

    const perkPrices = {
        regular: { amount },
        recurring: {
            amount: recurringAmount,
        },
    };

    const defaultPaymentMode =
        paymentOptions === PERKS_PAYMENT_OPTIONS.RECURRING
            ? PAYMENT_MODES.RECURRING
            : PAYMENT_MODES.REGULAR;

    const { amount: perkAmount } = perkPrices[defaultPaymentMode];

    let selectedLayerItem =
        selectedLayerItemOnLayerPage || selectedLayerItemOnCampaignPage;

    if (selectedLayerItem) {
        const layerId = selectedLayerItem.layerId;
        tab.map(layer => {
            if (layer.layer) {
                if (layer.layer.id === layerId && !layer.layer.allowDonations) {
                    selectedLayerItem = null;
                }
            }
        });
    }

    const handleOpen = useCallback(() => {
        if (isStockEmpty) {
            return null;
        }
        dispatch(updateDonationAmount(perkAmount * count));
        dispatch(updateGivingCount(count));
        dispatch(updateGivingAmount(perkAmount));
        dispatch(
            updateGivingPaymentOptions({
                type: paymentOptions,
                duration: duration,
                enableRegularPayments,
                enableRecurringPayments,
            }),
        );
        dispatch(updatePerkPrices(perkPrices));
        dispatch(updateDonationCurrency(currency));
        dispatch(open({ perk: id, layerItem: selectedLayerItem }));
        dispatch(updateGivingId(id));
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        history.push(
            `${history.location.pathname.replace(
                /\/+$/,
                '',
            )}/donate/${queryString}`,
        );
    }, [count, selectedLayerItem]);

    const countUp = () => {
        if (isStock && count >= inStock - soldCount) {
            return;
        }

        if (isLimited && count >= limit) {
            return;
        }

        setCount(count + 1);
    };

    const countDown = () => {
        if (count === 1) {
            return;
        }

        setCount(count - 1);
    };
    const isStockEmpty = isStock && inStock - soldCount <= 0;
    const shouldShowDivider =
        isDescriptionEnabled ||
        isNameEnabled ||
        (perkAmount > 0 && isPriceEnabled) ||
        isShowAmountSold ||
        isShowAmountLeft ||
        isSelectEnabled;

    const renderDonateBtnLabel = () => {
        if (isStockEmpty) {
            return <FormattedMessage id="Perks.soldOut" />;
        }

        if (perkAmount > 0) {
            const recurringCount =
                paymentOptions === 'recurring' ? duration : 1;
            return (
                <>
                    <FormattedMessage id="Perks.donate" />{' '}
                    {currencySign(currency)}
                    {perkAmount.toLocaleString('en-EN')}
                    {recurringCount > 1 && `×${recurringCount}`}
                </>
            );
        }

        return <FormattedMessage id="Perks.donate" />;
    };

    if (!isActive) {
        return null;
    }

    return (
        <div className={classNames(className, classes.perkItem)}>
            <figure
                className={classNames(classes.imagePlaceholder, {
                    [classes.noImage]: !logo,
                })}
            >
                {logo && (
                    <img
                        src={logo}
                        style={{
                            border: `5px solid ${
                                isBecomeGrey && isStockEmpty
                                    ? palette.grey[100]
                                    : color
                            }`,
                        }}
                    />
                )}
            </figure>

            <div className={classNames(classes.info, 'perk-content')}>
                {shouldShowDivider && (
                    <>
                        <StarDivider className="divider" />
                    </>
                )}
                {perkAmount > 0 && isPriceEnabled && (
                    <>
                        <span className={classes.perkAmount}>
                            {currencySign(currency)}
                            {perkAmount.toLocaleString('en-EN')}
                            {paymentOptions === 'recurring' && `×${duration}`}
                        </span>
                    </>
                )}
                {(isDescriptionEnabled || isNameEnabled) && (
                    <div
                        className={classNames(
                            classes.text,
                            perkAmount > 0 && isPriceEnabled ? '' : 'no-border',
                        )}
                    >
                        {isNameEnabled && (
                            <span className={classes.title}>{name}</span>
                        )}{' '}
                        {isDescriptionEnabled && (
                            <p className={classes.paragraph}>{description}</p>
                        )}
                    </div>
                )}

                <div className={classes.perkBottom}>
                    <div className={classes.perkBottom}>
                        <div
                            className={
                                isShowAmountSold || isShowAmountLeft
                                    ? classes.gifts
                                    : ''
                            }
                        >
                            {isShowAmountSold && (
                                <div className={classes.giftsItem}>
                                    <div className={classes.ticketGroup}>
                                        <Ticket />
                                        <div className={classes.soldAmount}>
                                            {soldCount}
                                        </div>{' '}
                                        <span>
                                            <FormattedMessage id="Perks.giftsSold" />
                                        </span>
                                    </div>
                                </div>
                            )}
                            {inStock && isShowAmountLeft && (
                                <div className={classes.giftsItem}>
                                    <div className={classes.ticketGroup}>
                                        <Ticket />
                                        <div className={classes.leftAmount}>
                                            {inStock - soldCount}
                                        </div>{' '}
                                        <span>
                                            <FormattedMessage id="Perks.giftsLeft" />
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {isSelectEnabled &&
                            !(isLimited && limit <= 1) &&
                            !isStockEmpty && (
                                <div className={classes.selectUnits}>
                                    <p>
                                        <FormattedMessage id="Perks.selectUnits" />
                                    </p>
                                    <div className={classes.unitControls}>
                                        <button
                                            className={classes.minus}
                                            onClick={countDown}
                                        ></button>
                                        <span className={classes.unitsCount}>
                                            {count}
                                        </span>
                                        <button
                                            className={classes.plus}
                                            onClick={countUp}
                                        ></button>
                                    </div>
                                </div>
                            )}
                        <PerkButton
                            onClick={handleOpen}
                            disabled={disabled || isStockEmpty}
                        >
                            {renderDonateBtnLabel()}
                        </PerkButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

Perk.propTypes = {
    id: PropTypes.number.isRequired,
    logo: PropTypes.string,
    color: PropTypes.string,
    inStock: PropTypes.number,
    className: PropTypes.string,
    isShowAmountSold: PropTypes.bool.isRequired,
    isShowAmountLeft: PropTypes.bool.isRequired,
    isBecomeGrey: PropTypes.bool.isRequired,
    isLimited: PropTypes.bool.isRequired,
    isSelectEnabled: PropTypes.bool.isRequired,
    limit: PropTypes.number,
    isStock: PropTypes.bool.isRequired,
    soldCount: PropTypes.number,
    paymentOptions: PropTypes.string.isRequired,
    duration: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    isNameEnabled: PropTypes.bool,
    isDescriptionEnabled: PropTypes.bool,
    isPriceEnabled: PropTypes.bool.isRequired,
    enableRecurringPayments: PropTypes.bool.isRequired,
    enableRegularPayments: PropTypes.bool.isRequired,
};

export default Perk;
